import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "../Login/Login";
import ProtectedRoute from "../Auth/ProtectedRoute";
import Home from "../Home";
import {AuthProvider} from "../Auth/AuthContext";
import CreateSimulation from "../Simulation/Create/CreateSimulation";
import {RequestProvider} from "../Api/RequestContext";
import SimulationList from "../SimulationList/SimulationList";
import SimulationView from "../SimulationView/SimulationView";
import './App.css';
import CoinList from "../Coin/List/CoinList";
import CoinView from "../Coin/View/CoinView";
import SimulationIndicators from "../Simulation/Incidacors/Indicators";

const App: React.FC = () => {
    return (
        <AuthProvider>
        <RequestProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
                    <Route path="/simulation/create" element={<ProtectedRoute><CreateSimulation/></ProtectedRoute>}/>
                    <Route path="/simulation" element={<ProtectedRoute><SimulationList/></ProtectedRoute>}/>
                    <Route path="/coin" element={<ProtectedRoute><CoinList/></ProtectedRoute>}/>
                    <Route path="/coin/:symbol" element={<ProtectedRoute><CoinView/></ProtectedRoute>}/>
                    <Route path="/simulation/:id" element={<ProtectedRoute><SimulationView/></ProtectedRoute>}/>
                    <Route path="/simulation/:id/indicator" element={<ProtectedRoute><SimulationIndicators/></ProtectedRoute>}/>
                </Routes>
            </Router>
        </RequestProvider>
        </AuthProvider>
    );
};

export default App;
