import React, {useEffect, useRef, useState} from "react";
import Layout from "../../Layout/Layout";
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {createChart, CrosshairMode, ISeriesApi, LogicalRange, LogicalRangeChangeEventHandler} from "lightweight-charts";
import api from "../../Api";

let randomFactor = 25 + Math.random() * 25;

const CoinView: React.FC = () => {
    const {symbol} = useParams<{ symbol: string }>();
    const interval = "5";
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<ReturnType<typeof createChart> | null>(null);
    const candlestickSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null);
    const earliestTimeRef = useRef<number | null>(null); // Используем реф вместо состояния
    const isLoadingRef = useRef<boolean>(true); // Флаг загрузки
    const {getQuotations} = api.useGetQuotations();

    useEffect(() => {
        renderChart();
    }, []);

    const fetchQuotations = async (from: number, to: number) => {
        if (!symbol) return [];

        try {
            const response = await getQuotations(from, to, interval, symbol);
            return response.data.quotations.map((item: any) => ({
                time: item.time,    // предположим, что API возвращает UNIX-время в секундах
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
            }));
        } catch (error) {
            console.error("Failed to fetch quotations:", error);
            return [];
        }
    };

    const renderChart = async () => {
        if (chartContainerRef.current && !chartRef.current) {
            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                timeScale: {
                    timeVisible: true,
                    secondsVisible: true,
                },
                layout: {
                    textColor: '#d1d4dc',
                    background: {color: '#131722'},
                },
                grid: {
                    vertLines: {
                        color: '#2B2B43',
                    },
                    horzLines: {
                        color: '#2B2B43',
                    },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
            });
            chartRef.current = chart;

            candlestickSeriesRef.current = chart.addCandlestickSeries({
                upColor: '#4AFA9A',
                downColor: '#FF4976',
                borderDownColor: '#FF4976',
                borderUpColor: '#4AFA9A',
                wickDownColor: '#ff4976',
                wickUpColor: '#4afa9a',
                priceFormat: {
                    type: 'price',
                },
            });

            // Начальная загрузка данных
            const endDate = Math.floor(Date.now() / 1000);
            const startDate = endDate - 60 * 5 * 1000;

            const initialData = await fetchQuotations(startDate, endDate);

            candlestickSeriesRef.current.setData(initialData);

            if (initialData.length > 0) {
                earliestTimeRef.current = initialData[0].time;
                isLoadingRef.current = false;
            }


            // Установка данных prevCandles
            chart.timeScale().fitContent();

            chart.timeScale().subscribeVisibleLogicalRangeChange(async (logicalRange:any) => {

                if (logicalRange.from < 10 && earliestTimeRef.current && !isLoadingRef.current) {
                    isLoadingRef.current = true;
                    const numberOfDays = 1000; // Определите, сколько дней загружать
                    const fromDate = earliestTimeRef.current - numberOfDays * 60 * 5;
                    const moreData = await fetchQuotations(fromDate, earliestTimeRef.current - 5);

                    if (moreData.length > 0) {
                        candlestickSeriesRef.current?.setData([...moreData, ...initialData]);
                        earliestTimeRef.current = moreData[0].time; // обновляем earliestTime для следующей загрузки
                    }
                    isLoadingRef.current = false;
                }
            });
        }

        chartRef.current?.timeScale().fitContent();
    }
    return (
        <Layout>
            <Row>
                <Col lg={9}>
                    <div className={"chart-container"} ref={chartContainerRef}/>
                </Col>
                <Col lg={3}></Col>
            </Row>
        </Layout>
    );
}

export default CoinView;