import React, {useEffect, useState} from 'react';
import './CoinList.css';
import Layout from './../../Layout';
import api from '../../Api/api';
import {Button, Col, Form, Row, Table} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

export interface Coin {
    symbol: string;
    pricePrecision: number;
    coinPrecision: number;
}

const CoinList: React.FC = () => {
    const [coins, setCoins] = useState<Coin[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const {getCoins, syncCoins} = api.useCoins();
    const [filter, setFilter] = useState<string>(''); // Новое состояние для фильтра


    useEffect(() => {
        fetchCoins();
    }, []);

    const fetchCoins = async () => {
        try {
            const response = await getCoins();

            const coins = response.data.coins.map((item: any) => ({
                symbol: item.symbol,
                pricePrecision: item.pricePrecision,
                coinPrecision: item.coinPrecision
            }));

            setCoins(coins);
        } catch (err: any) {
            setError('Failed to fetch coins');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Layout><LoadingScreen/></Layout>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Фильтрация монет на основе значения фильтра
    const filteredCoins = coins.filter((coin) =>
        coin.symbol.toLowerCase().includes(filter.toLowerCase())
    );

    const handleSyncCoins = async () => {
        try {
            await syncCoins();
            await fetchCoins();
        } catch (err) {
            console.error('Failed to delete coin', err);
        }
    }

    function handleGoToCoin(symbol: string) {
        navigate('/coin/' + symbol);
    }

    return (
        <Layout>
            <Row className={"header-row"}>
                <Col lg={"12"}>
                    <Form.Control
                        type="text"
                        placeholder="Фильтр по символу"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <Button variant={"primary"} onClick={()=> handleSyncCoins()}>SYNC</Button>
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover className={"coin-list"}>
                    <thead>
                    <tr>
                        <th>Монета</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredCoins.map((coin) => (
                        <tr key={coin.symbol}>
                            <td>
                                <a onClick={() => handleGoToCoin(coin.symbol)}>{coin.symbol}</a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Row>
        </Layout>
    );
};

export default CoinList;
